.qrcode {
  width: 100%;
}

.main-icon {
  font-size: 400%;
}

@media (min-width: 768px) {
  .main-icon {
    font-size: 500%;
  }
}

.modal-header {
  border-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

.telegram-holder {
  padding: 40px 0 0 !important;
}

/*# sourceMappingURL=index.b01c8207.css.map */
